<template>
  <a-scene>

    <!--<a-box position="0 3 -3" rotation="0 45 0" color="#4CC3D9"></a-box>



    <a-box position="-1 0.5 -3" rotation="0 45 0" color="#4CC3D9"></a-box>
    <a-sphere position="0 1.25 -5" radius="1.25" color="#EF2D5E"></a-sphere>
    <a-cylinder position="1 0.75 -3" radius="0.5" height="1.5" color="#FFC65D"></a-cylinder>-->


    
    <a-cylinder id="ground" src="https://media.istockphoto.com/photos/background-texture-of-rough-asphalt-picture-id482030962?k=20&m=482030962&s=170667a&w=0&h=snWlaEKHzwlxlCJMM_rcRYEgU_fc8mbQqa7jMTmACFw=" radius="32" height="0.1"></a-cylinder>


    <a-assets>
      
      <video ref="myvideo" id="video" src="/dragonballz.mp4"></video>
      <img id="skyTexture" src="/sky.webp">



      <img id="iconplay" src="/icon-play.svg">
      <img id="iconpause" src="/icon-pause.svg">
    </a-assets>





    <a-camera>
      <a-cursor color="white"></a-cursor>
    </a-camera>
    




    
    <a-video src="#video" width="16" height="8" position="0 5 -9">

      <a-image height="3" @click="playpause" width="3" v-if="paused"  src="#iconplay"  position="0 -4.5 0" scale="0.5 0.5 1"></a-image>

      <a-image height="3" @click="playpause" width="3" v-else src="#iconpause"  position="0 -4.5 0" scale="0.5 0.5 1"></a-image>

    </a-video>
  
    
    <a-sky id="background" src="#skyTexture" theta-length="90" radius="30"></a-sky>
  
  </a-scene>
</template>

<script>

export default {
  name: 'App',

  data: () => {
    return {
      videos: [
        {
          src: '/teste-2.mp4'
        }
        
      ],
      paused: true
    }
  },

  mounted () {
  
  },
  
  methods: {
    playpause () {
      console.log('AAAA')
      if (this.$refs.myvideo.paused) {
        this.$refs.myvideo.play()
        this.paused = false
        
      } else {
        this.$refs.myvideo.pause()
        this.paused = true
      }
    },
  }
  
}
</script>

<style>

</style>
